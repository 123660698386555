// import React, { useState } from "react";
// import {
//   Checkbox,
//   Button,
//   List,
//   Typography,
//   message,
//   Divider,
//   Input,
//   Modal, // Import Modal from antd
// } from "antd";
// import { CheckCircleOutlined } from "@ant-design/icons";

// import { CloseCircleOutlined } from "@ant-design/icons";
// import CategoryGrid from "./CategoryGrid";
// import Page from "../../layouts/Page";
// import { useLocation, useNavigate } from "react-router-dom";
// import cover from "../../assets/categoires_astro/ask_astroguru_banner.jpeg";
// import { categoryQuestions } from "./categoryQuestions";
// import Footer from "../../layouts/Footer";
// import { useSelector } from "react-redux";
// import PoojaCarousel from "../pooja/PoojaCarousel";
// import CustomButton from "../namesCorrection/CustomButton";
// import BottomNavBar from "../../layouts/BottomNavBar";

// function AstroGuruFind() {
//   const navigate = useNavigate();
//   const { state } = useLocation();

//   const [selectedQuestions, setSelectedQuestions] = useState([]);
//   const [questionsJson, setQuestionsJson] = useState({});
//   const [activeCategory, setActiveCategory] = useState(
//     state?.key ? state?.key : "career"
//   );

//   const [customQuestion, setCustomQuestion] = useState("");
//   const { homeData } = useSelector((state) => state?.homeReducer);
//   const [isModalVisible, setIsModalVisible] = useState(false); // State for Modal visibility

//   // Dynamic quePrice based on the number of selected questions
//   const quePrice = selectedQuestions.length >= 5 ? 200 : 250;

//   // Calculate savings for >= 4 questions
//   const calculateSavings = () => {
//     const extraQuestions = selectedQuestions.length - 5;
//     if (extraQuestions >= 0) {
//       return 250 + extraQuestions * 50;
//     }
//     return 0;
//   };

//   const discountMessage = (() => {
//     const questionCount = selectedQuestions.length;

//     if (questionCount === 0 || questionCount === 1 || questionCount === 2) {
//       return ""; // No message for 1 or 2 questions
//     } else if (questionCount === 3) {
//       return "Add one more and get one free!";
//     } else if (questionCount === 4) {
//       return "Congratulations! Add one more for free!";
//     } else if (questionCount >= 5) {
//       const savings = calculateSavings();
//       return `Congratulations! You saved ₹${savings}`;
//     } else {
//       return "";
//     }
//   })();

//   const handleSelection = (question, category = activeCategory) => {
//     const isSelected = selectedQuestions.includes(question);
//     let updatedQuestionsJson = { ...questionsJson };

//     if (isSelected) {
//       setSelectedQuestions(
//         selectedQuestions?.filter((item) => item !== question)
//       );
//       updatedQuestionsJson[category] = updatedQuestionsJson[category]?.filter(
//         (item) => item !== question
//       );
//     } else {
//       setSelectedQuestions([...selectedQuestions, question]);
//       if (!updatedQuestionsJson[category]) {
//         updatedQuestionsJson[category] = [];
//       }
//       updatedQuestionsJson[category] = [
//         ...updatedQuestionsJson[category],
//         question,
//       ];
//     }
//     if (updatedQuestionsJson[category]?.length === 0) {
//       delete updatedQuestionsJson[category];
//     }
//     setQuestionsJson(updatedQuestionsJson);
//   };


//   const [open, setOpen] = useState(false);
 

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);


//   const addCustomQuestion = () => {
//     if (!customQuestion) return;
//     handleSelection(customQuestion, "other");
//     setCustomQuestion("");
//   };

//   const submitHandler = async () => {
//     if (selectedQuestions.length === 0) {
//       message.error("Select at least one question!");
//       return;
//     }
//     // Show the modal if exactly 4 questions are selected
//     if (selectedQuestions.length === 4) {
//       setIsModalVisible(true);
//       return; // Don't navigate yet, wait for modal response
//     }

//     // If 5 or more questions are selected, navigate directly
//     navigate("/astro-guru/form", {
//       state: {
//         selectedQuestions,
//         questionsJson,
//         totalAmount: (selectedQuestions.length * quePrice).toFixed(2),
//       },
//     });
//   };

//   let banners = homeData.banner?.filter((item) => item.page === "astro_guru");
//   let bannersData = banners?.map((item) => item.url);

//   return (
//     <Page>
//       <PoojaCarousel
//         product={{
//           name: "",
//           images: bannersData?.length > 0 ? bannersData : [cover],
//         }}
//         setBgBlur={() => {}}
//       />

//       <div className="bg-white min-h-screen px-4">
//         <div className="max-w-3xl mx-auto bg-white rounded-lg p-1">
    
//           <h2 className="text-xl font-semibold text-center">
//             Get Answers from our expert{" "}
//             <span className="text-orange-500">AstroGuru</span>
//           </h2>

//           <div className="mt-3">
//           <h2 className="text-xl font-semibold text-center">
//         Ask 1 question @ {" "}
//             <span className="text-green-500">₹350</span>
//           </h2>

      
//           </div>
//           <div className="flex justify-between mt-3 mb-3">
//             {/* <Button  type="primary" size="larze" className="bg-red-800"
//             onClick={() => navigate(`/name-correction/`)}
//           > Name Correction </Button> */}
//             <Button
//               type="primary"
//               size="large"
//               className="bg-orange-600 text-white "
//               onClick={() => navigate(`/name-correction/`)}
//             >
//               Name Correction
//             </Button>

//             <Button
//               type="primary"
//                  size="large"
//               className="bg-orange-600 text-white "
//               onClick={handleOpen}
//             >
//               Type Your Question
//             </Button>
//           </div>


//           <Modal
//         title="Type Your Question"
//         visible={open}
//         onCancel={handleClose}
//         onOk={addCustomQuestion}
//         // okText="Add"
//         okText="Pay Now" 
//         okButtonProps={{
//           style: { backgroundColor: 'green', color: 'white' },
//           onClick:submitHandler,
//         }}
//       >

 
//         <Input
//           className="mt-6"
//           placeholder="Type your question here..."
//           value={customQuestion}
//           onChange={(e) => setCustomQuestion(e.target.value)}
//           onPressEnter={addCustomQuestion}
//           suffix={
//             <Button type="link" onClick={addCustomQuestion}>
//               Add
//             </Button>
//           }
//         />
// <>
// <div className="mt-6 bg-gray-100 p-4 rounded-lg">
//             <h3 className="  font-semibold text-lg">
//               Questions for Ask an AstroGuru
//             </h3>
//             <List
//               dataSource={selectedQuestions}
//               renderItem={(item) => (
//                 <List.Item
//                   className="flex justify-between items-center"
//                   actions={[
//                     <div className="flex items-center space-x-4">
//                       <span className="text-gray-500">₹{quePrice}</span>
//                       <CloseCircleOutlined
//                         className="text-red-500"
//                         onClick={() => handleSelection(item, "other")}
//                       />
//                     </div>,
//                   ]}
//                 >
//                   <p>{item}</p>
//                 </List.Item>
//               )}
//               footer={
//                 <div className="mt-4 space-y-2">
//                   <div className="flex justify-between font-semibold text-lg">
//                     <span>Total Amount:</span>
//                     <span>
//                       ₹{(selectedQuestions.length * quePrice).toFixed(2)}
//                     </span>
//                   </div>
//                   <Typography.Text type="secondary">
//                     {discountMessage}
//                   </Typography.Text>
//                 </div>
//               }
//             />
//           </div>
// </>

//       </Modal>


//           <Typography.Text type="secondary" className="mb-4 block">
//             Get personalized remedies, puja, and gemstone recommendations from
//             AstroGuru within 48 hours. Submit your questions, birth details, and
//             contact info to receive solutions based on your kundali.
//           </Typography.Text>

//           <CategoryGrid
//             setActiveCategory={setActiveCategory}
//             activeCategory={activeCategory}
//           />

//           <div className="border p-2 mt-6 rounded-lg">
//             <Typography.Text className="text-2xl">
//               {activeCategory.charAt(0).toUpperCase() + activeCategory.slice(1)}
//             </Typography.Text>
//             <div className="grid grid-cols-1 gap-4 mt-2">
//               {categoryQuestions[activeCategory].map((question, index) => (
//                 <div className="border rounded-lg" key={index}>
//                   <Checkbox
//                     onChange={() => handleSelection(question)}
//                     className={`p-4 rounded-lg ${
//                       selectedQuestions.includes(question)
//                         ? "bg-orange-100"
//                         : ""
//                     }`}
//                     checked={selectedQuestions.includes(question)}
//                   >
//                     {question}
//                   </Checkbox>
//                 </div>
//               ))}
//             </div>
//           </div>

//           <Input
//             className="mt-6"
//             placeholder="Type your question here..."
//             value={customQuestion}
//             onChange={(e) => setCustomQuestion(e.target.value)}
//             onPressEnter={addCustomQuestion}
//             suffix={
//               <Button type="link" onClick={addCustomQuestion}>
//                 Add
//               </Button>
//             }
//           />

//           <div className="mt-6 bg-gray-100 p-4 rounded-lg">
//             <h3 className="text-xl font-semibold">
//               Questions for Ask an AstroGuru
//             </h3>
//             <List
//               dataSource={selectedQuestions}
//               renderItem={(item) => (
//                 <List.Item
//                   className="flex justify-between items-center"
//                   actions={[
//                     <div className="flex items-center space-x-4">
//                       <span className="text-gray-500">₹{quePrice}</span>
//                       <CloseCircleOutlined
//                         className="text-red-500"
//                         onClick={() => handleSelection(item, "other")}
//                       />
//                     </div>,
//                   ]}
//                 >
//                   <p>{item}</p>
//                 </List.Item>
//               )}
//               footer={
//                 <div className="mt-4 space-y-2">
//                   <div className="flex justify-between font-semibold text-lg">
//                     <span>Total Amount:</span>
//                     <span>
//                       ₹{(selectedQuestions.length * quePrice).toFixed(2)}
//                     </span>
//                   </div>
//                   <Typography.Text type="secondary">
//                     {discountMessage}
//                   </Typography.Text>
//                 </div>
//               }
//             />
//           </div>

//           <div className="mt-6 absolute bottom-0 left-0 right-0 bg-white">
//             {selectedQuestions.length >= 1 && (
//               <Typography.Text className="text-center block font-bold text-orange-500 py-2">
//                 {discountMessage}
//               </Typography.Text>
//             )}

//             {selectedQuestions?.length !== 0 && (
//               <div
//                 className={`p-1 w-full ${
//                   selectedQuestions?.length === 0
//                     ? "bg-gray-400"
//                     : "bg-green-500"
//                 } `}
//               >
//                 <Button
//                   size="large"
//                   className="w-full border-none text-white font-medium "
//                   onClick={submitHandler}
//                   disabled={selectedQuestions?.length === 0}
//                 >
//                   Ask AstroGuru Expert (
//                   <span>₹{parseInt(selectedQuestions.length * quePrice)}</span>)
//                 </Button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {/* <Modal
//         title=""
//         visible={isModalVisible}
//         onCancel={() => setIsModalVisible(false)} // This will close the modal when Skip is clicked
//         footer={[
//           <Button
//             key="skip"
//             className="text-black outline-none border-black"
//             onClick={() => setIsModalVisible(false)}
//           >
//             Add Question
//           </Button>,
//           <Button
//             key="payNow"
//             className="bg-green-600 text-white outline-none border-none w-32 mr-8"
//             onClick={() => {
//               setIsModalVisible(false); // Close the modal

//               navigate("/astro-guru/form", {
//                 state: {
//                   selectedQuestions,
//                   questionsJson,
//                   totalAmount: (selectedQuestions.length * quePrice).toFixed(2),
//                 },
//               });
//             }}
//           >
//             Pay Now
//           </Button>,
//         ]}
//       >
//         <div className="text-center">
//           <div style={{ fontSize: "3rem" }}>🎉</div>
//           <p className="text-orange-500 text-2xl mt-4">Congratulations!</p>
//           <p className="text-gray-800 text-lg font-bold mb-4 mt-1">
//             You have unlocked one FREE question.
//           </p>
//         </div>
//       </Modal> */}
//       <div className="mt-6 flex flex-col gap-3 mb-4 px-6">
//         <Typography.Text className="block text-2xl w-full text-center">
//           Is your <span className="text-[#A8701C]">NAME</span> holding you back
//           from success?
//         </Typography.Text>

//         <CustomButton
//         style={{}}
//           onClick={() => navigate(`/name-correction/`)}
//           text="Get started with Our Expert Numerologist's name suggestions"
//         />
//       </div>

//       <Footer />

//       {selectedQuestions?.length > 0 && (
//         <>
//           {" "}
//           <Divider />
//           <Divider />
        
//         </>
//       )}
       
//     </Page>
//   );
// }

// export default AstroGuruFind;


import React, { useState } from "react";
import {
  Checkbox,
  Button,
  List,
  Typography,
  message,
  Divider,
  Input,
  Modal, // Import Modal from antd
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import CategoryGrid from "./CategoryGrid";
import Page from "../../layouts/Page";
import { useLocation, useNavigate } from "react-router-dom";
import cover from "../../assets/categoires_astro/ask_astroguru_banner.jpeg";
import { categoryQuestions } from "./categoryQuestions";
import Footer from "../../layouts/Footer";
import { useSelector } from "react-redux";
import PoojaCarousel from "../pooja/PoojaCarousel";
import CustomButton from "../namesCorrection/CustomButton";
import BottomNavBar from "../../layouts/BottomNavBar";

function AstroGuruFind() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsJson, setQuestionsJson] = useState({});
  const [activeCategory, setActiveCategory] = useState(
    state?.key ? state?.key : "career"
  );

  const [customQuestion, setCustomQuestion] = useState("");
  const { homeData } = useSelector((state) => state?.homeReducer);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for Modal visibility

  // Fixed price of ₹350 per question
  const quePrice = 350;

  const handleSelection = (question, category = activeCategory) => {
    const isSelected = selectedQuestions.includes(question);
    let updatedQuestionsJson = { ...questionsJson };

    if (isSelected) {
      setSelectedQuestions(
        selectedQuestions?.filter((item) => item !== question)
      );
      updatedQuestionsJson[category] = updatedQuestionsJson[category]?.filter(
        (item) => item !== question
      );
    } else {
      setSelectedQuestions([...selectedQuestions, question]);
      if (!updatedQuestionsJson[category]) {
        updatedQuestionsJson[category] = [];
      }
      updatedQuestionsJson[category] = [
        ...updatedQuestionsJson[category],
        question,
      ];
    }
    if (updatedQuestionsJson[category]?.length === 0) {
      delete updatedQuestionsJson[category];
    }
    setQuestionsJson(updatedQuestionsJson);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addCustomQuestion = () => {
    if (!customQuestion) return;
    handleSelection(customQuestion, "other");
    setCustomQuestion("");
  };

  const submitHandler = async () => {
    if (selectedQuestions.length === 0) {
      message.error("Select at least one question!");
      return;
    }

    // Navigate directly with selected questions and calculated total
    navigate("/astro-guru/form", {
      state: {
        selectedQuestions,
        questionsJson,
        totalAmount: (selectedQuestions.length * quePrice).toFixed(2),
      },
    });
  };

  let banners = homeData.banner?.filter((item) => item.page === "astro_guru");
  let bannersData = banners?.map((item) => item.url);

  return (
    <Page>
      <PoojaCarousel
        product={{
          name: "",
          images: bannersData?.length > 0 ? bannersData : [cover],
        }}
        setBgBlur={() => {}}
      />

      <div className="bg-white min-h-screen px-4">
        <div className="max-w-3xl mx-auto bg-white rounded-lg p-1">
          <h2 className="text-xl font-semibold text-center">
            Get Answers from our expert{" "}
            <span className="text-orange-500">AstroGuru</span>
          </h2>

          <div className="mt-3">
            <h2 className="text-xl font-semibold text-center">
              Ask 1 question @ <span className="text-green-500">₹350</span>
            </h2>
          </div>
          <div className="flex justify-between mt-3 mb-3">
            <Button
              type="primary"
              size="large"
              className="bg-orange-600 text-white"
              onClick={() => navigate(`/name-correction/`)}
            >
              Name Correction
            </Button>

            <Button
              type="primary"
              size="large"
              className="bg-orange-600 text-white"
              onClick={handleOpen}
            >
              Type Your Question
            </Button>
          </div>

          <Modal
            title="Type Your Question"
            visible={open}
            onCancel={handleClose}
            onOk={addCustomQuestion}
            okText="Pay Now"
            okButtonProps={{
              style: { backgroundColor: "green", color: "white" },
              onClick: submitHandler,
            }}
          >
            <Input
              className="mt-6"
              placeholder="Type your question here..."
              value={customQuestion}
              onChange={(e) => setCustomQuestion(e.target.value)}
              onPressEnter={addCustomQuestion}
              suffix={
                <Button type="link" onClick={addCustomQuestion}>
                  Add
                </Button>
              }
            />



            <div className="mt-6 bg-gray-100 p-4 rounded-lg">
              <h3 className="font-semibold text-lg">
                Questions for Ask an AstroGuru
              </h3>
              <List
                dataSource={selectedQuestions}
                renderItem={(item) => (
                  <List.Item
                    className="flex justify-between items-center"
                    actions={[
                      <div className="flex items-center space-x-4">
                        <span className="text-gray-500">₹{quePrice}</span>
                        <CloseCircleOutlined
                          className="text-red-500"
                          onClick={() => handleSelection(item, "other")}
                        />
                      </div>,
                    ]}
                  >
                    <p>{item}</p>
                  </List.Item>
                )}
                footer={
                  <div className="mt-4 space-y-2">
                    <div className="flex justify-between font-semibold text-lg">
                      <span>Total Amount:</span>
                      <span>
                        ₹{(selectedQuestions.length * quePrice).toFixed(2)}
                      </span>
                    </div>
                  </div>
                }
              />
            </div>
          </Modal>

          <Typography.Text type="secondary" className="mb-4 block">
            Get personalized remedies, puja, and gemstone recommendations from
            AstroGuru within 48 hours. Submit your questions, birth details, and
            contact info to receive solutions based on your kundali.
          </Typography.Text>

          <CategoryGrid
            setActiveCategory={setActiveCategory}
            activeCategory={activeCategory}
          />

          <div className="border p-2 mt-6 rounded-lg">
            <Typography.Text className="text-2xl">
              {activeCategory.charAt(0).toUpperCase() +
                activeCategory.slice(1)}
            </Typography.Text>
            <div className="grid grid-cols-1 gap-4 mt-2">
              {categoryQuestions[activeCategory].map((question, index) => (
                <div className="border rounded-lg" key={index}>
                  <Checkbox
                    onChange={() => handleSelection(question)}
                    className={`p-4 rounded-lg ${
                      selectedQuestions.includes(question)
                        ? "bg-orange-100"
                        : ""
                    }`}
                    checked={selectedQuestions.includes(question)}
                  >
                    {question}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>

          <Input
              className="mt-6"
              placeholder="Type your question here..."
              value={customQuestion}
              onChange={(e) => setCustomQuestion(e.target.value)}
              onPressEnter={addCustomQuestion}
              suffix={
                <Button type="link" onClick={addCustomQuestion}>
                  Add
                </Button>
              }></Input>

          <div className="mt-6 bg-gray-100 p-4 rounded-lg">
            <h3 className="text-xl font-semibold">
              Questions for Ask an AstroGuru
            </h3>
            <List
              dataSource={selectedQuestions}
              renderItem={(item) => (
                <List.Item
                  className="flex justify-between items-center"
                  actions={[
                    <div className="flex items-center space-x-4">
                      <span className="text-gray-500">₹{quePrice}</span>
                      <CloseCircleOutlined
                        className="text-red-500"
                        onClick={() => handleSelection(item, "other")}
                      />
                    </div>,
                  ]}
                >
                  <p>{item}</p>
                </List.Item>
              )}
              footer={
                <div className="mt-4 space-y-2">
                  <div className="flex justify-between font-semibold text-lg">
                    <span>Total Amount:</span>
                    <span>
                      ₹{(selectedQuestions.length * quePrice).toFixed(2)}
                    </span>
                  </div>
                </div>
              }
            />
          </div>

          <div className="mt-6 absolute bottom-0 left-0 right-0 bg-white">
             

           {selectedQuestions?.length !== 0 && (
               <div
                  className={`p-1 w-full ${
                   selectedQuestions?.length === 0
                    ? "bg-gray-400"
                     : "bg-green-500"
                 } `}
                >
                 <Button
                    size="large"
                   className="w-full border-none text-white font-medium "
                   onClick={submitHandler}
                    disabled={selectedQuestions?.length === 0}
                 >
                    Ask AstroGuru Expert (
                    <span>₹{parseInt(selectedQuestions.length * quePrice)}</span>)
                  </Button>
                </div>
             )}
            </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
}

export default AstroGuruFind;
