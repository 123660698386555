// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

// import AstroGuru from "../assets/astrology.png";
// import book_puja from "../assets/book_puja.png";
// import book_puja2 from "../assets/book_puja2.png";
// import play from "../assets/play.png";
// import yantra from "../assets/torus-yantra.png";
// import My_Order from "../assets/check-out.png";
// import parchment from "../assets/parchment.png";
// import nametag from "../assets/name_tag.png";
// import nametag2 from "../assets/name_tag2.png";

// function BottomNavBar() {
//   const navigate = useNavigate();
//   const [isPujaClicked, setIsPujaClicked] = useState(false);
//   const [isNameClicked, setIsNameClicked] = useState(false);

//   const handleClickPuja = () => {
//     setIsPujaClicked(true);
//     navigate("/online-puja");
//   };

//   const handleClickName = () => {
//     setIsNameClicked(true);
//     navigate(`/name-correction/`);
//   };

//   return (
//     <div className="bottom-nav-bar fixed bottom-0 left-0 right-0 bg-white flex justify-between items-center px-6 h-14 shadow-md border-t border-gray-200">
//   <div
//   className="nav-item flex flex-col items-center"
//   onClick={handleClickPuja} // The onClick is now properly applied to the div
// >
//   {isPujaClicked ? (
//     <img src={book_puja2} className="h-8 w-8" alt="Puja tag clicked" />
//   ) : (
//     <img src={book_puja} className="h-8 w-8" alt="Puja tag" />
//   )}
//   <span className="nav-label text-xs">Puja</span>
// </div>


//       <div
//         className="nav-item flex flex-col items-center"
//         onClick={() => navigate("/astro-guru/find")}
//       >
//         <img src={AstroGuru} className="h-8 w-8" />
//         <span className="nav-label text-xs  ">Astro</span>
//       </div>

//       <div
//         className="nav-item flex flex-col items-center"
//         onClick={() => navigate("/abhimantrit-products")}
//       >
//         <img src={yantra} className="h-8 w-8" />
//         <span className="nav-label text-xs ">Products</span>
//       </div>

//       {/* <div
//         className="nav-item flex flex-col items-center"
//         onClick={() => navigate(`/name-correction/`)}
//       >
//         <img src={nametag} className="h-8 w-8" />

//         <img src={nametag2} className="h-8 w-8" />
//         <span className="nav-label text-xs">
//           Name
//         </span>
//       </div> */}

//       <div
//         className="nav-item flex flex-col items-center"
//         onClick={handleClickName}
//       >
//         {isNameClicked ? (
//           <img src={nametag2} className="h-8 w-8" alt="Name tag clicked" />
//         ) : (
//           <img src={nametag} className="h-8 w-8" alt="Name tag" />
//         )}
//         <span className="nav-label text-xs">Name</span>
//       </div>

//       <div
//         className="nav-item flex flex-col items-center"
//         onClick={() => {
//           navigate(`/orders/1/puja`);
//         }}
//       >
//         <img src={My_Order} className="h-8 w-8" />
//         <span className="nav-label text-xs ">Orders</span>
//       </div>
//     </div>
//   );
// }

// export default BottomNavBar;


// import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";

// import AstroGuru from "../assets/astrology.png";
// import AstroGuru2 from "../assets/astrology2.png";
// import book_puja from "../assets/book_puja.png";
// import book_puja2 from "../assets/book_puja2.png";
// import yantra from "../assets/torus-yantra.png";
// import yantra2 from "../assets/yantra2.png";
// import My_Order from "../assets/check-out.png";
// import My_Order2 from "../assets/check-out2.png";
// import nametag from "../assets/name_tag.png";
// import nametag2 from "../assets/name_tag2.png";

// function BottomNavBar() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   // Determine the active item based on the current path
//   const getActiveItem = () => {
//     const path = location.pathname;
//     if (path.startsWith("/online-puja")) return "puja";
//     if (path.startsWith("/astro-guru")) return "astro";
//     if (path.startsWith("/abhimantrit-products")) return "products";
//     if (path.startsWith("/name-correction")) return "name";
//     if (path.startsWith("/orders")) return "orders";
//     return null;
//   };

//   const activeItem = getActiveItem();

//   return (
//     <div className="bottom-nav-bar fixed bottom-0 left-0 right-0 bg-white flex justify-between items-center px-6 h-14 shadow-md border-t border-gray-200">
      
//       {/* Puja Nav Item */}
//       <div
//         className="nav-item flex flex-col items-center cursor-pointer"
//         onClick={() => navigate("/online-puja")}
//       >
//         <img
//           src={activeItem === "puja" ? book_puja2 : book_puja}
//           className="h-8 w-8"
//           alt={activeItem === "puja" ? "Puja tag clicked" : "Puja tag"}
//         />
//         <span className="nav-label text-xs">Puja</span>
//       </div>

//       {/* Astro Nav Item */}
//       <div
//         className="nav-item flex flex-col items-center cursor-pointer"
//         onClick={() => navigate("/astro-guru/find")}
//       >
//         <img
//            src={activeItem === "astro" ?  AstroGuru2 : AstroGuru}
//           // src={AstroGuru}
//           className={`h-8 w-8 ${activeItem === "astro" ? "astro tag  clicked" : "astro tag"}`}
//           alt="Astro tag"
//         />
//         <span className="nav-label text-xs">Astro</span>
//       </div>

//       {/* Products Nav Item */}
//       <div
//         className="nav-item flex flex-col items-center cursor-pointer"
//         onClick={() => navigate("/abhimantrit-products")}
//       >
//         <img
//           src={activeItem === "products" ?   yantra2 :  yantra}
//           // src={yantra}
//           className={`h-8 w-8 ${activeItem === "products" ? "opacity-90" : "products tag"}`}
//           alt="Products tag"
//         />
//         <span className="nav-label text-xs">Products</span>
//       </div>

//       {/* Name Correction Nav Item */}
//       <div
//         className="nav-item flex flex-col items-center cursor-pointer"
//         onClick={() => navigate("/name-correction/")}
//       >
//         <img
//           src={activeItem === "name" ? nametag2 : nametag}
//           className="h-8 w-8"
//           alt={activeItem === "name" ? "Name tag clicked" : "Name tag"}
//         />
//         <span className="nav-label text-xs">Name</span>
//       </div>

//       {/* Orders Nav Item */}
//       <div
//         className="nav-item flex flex-col items-center cursor-pointer"
//         onClick={() => navigate(`/orders/1/puja`)}
//       >
//         <img
//          src={activeItem === "orders" ?  My_Order2 :  My_Order}
//           // src={My_Order}
//           className={`h-8 w-8 ${activeItem === "orders" ? "orders tag clicked" : "orders tag"}`}
//           alt="Orders tag"
//         />
//         <span className="nav-label text-xs">Orders</span>
//       </div>
//     </div>
//   );
// }

// export default BottomNavBar;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AstroGuru from "../assets/astrology.png";
import AstroGuru2 from "../assets/astrology2.png";
import book_puja from "../assets/book_puja.png";
import book_puja2 from "../assets/book_puja2.png";
import yantra from "../assets/torus-yantra.png";
import yantra2 from "../assets/yantra2.png";
import My_Order from "../assets/check-out.png";
import My_Order2 from "../assets/check-out2.png";
import nametag from "../assets/name_tag.png";
import nametag2 from "../assets/name_tag2.png";

function BottomNavBar() {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the active item based on the current path
  const getActiveItem = () => {
    const path = location.pathname;
    if (path.startsWith("/online-puja")) return "puja";
    if (path.startsWith("/astro-guru")) return "astro";
    if (path.startsWith("/abhimantrit-products")) return "products";
    if (path.startsWith("/name-correction")) return "name";
    if (path.startsWith("/orders")) return "orders";
    return null;
  };

  const activeItem = getActiveItem();

  return (
    <div className="bottom-nav-bar fixed bottom-0 left-0 right-0 bg-white flex justify-between items-center px-6 h-14 shadow-md border-t border-gray-200">
      
      {/* Puja Nav Item */}
      <div
        className="nav-item flex flex-col items-center cursor-pointer"
        onClick={() => navigate("/online-puja")}
      >
        <img
          src={activeItem === "puja" ? book_puja2 : book_puja}
          className="h-8 w-8"
          alt={activeItem === "puja" ? "Puja tag clicked" : "Puja tag"}
        />
        <span className={`nav-label text-xs ${activeItem === "puja" ? "font-semibold" : ""}`}>Puja</span>
      </div>

      {/* Astro Nav Item */}
      <div
        className="nav-item flex flex-col items-center cursor-pointer"
        onClick={() => navigate("/astro-guru/find")}
      >
        <img
           src={activeItem === "astro" ? AstroGuru2 : AstroGuru}
          className={`h-8 w-8 ${activeItem === "astro" ? "astro tag clicked" : "astro tag"}`}
          alt="Astro tag"
        />
        <span className={`nav-label text-xs ${activeItem === "astro" ? "font-semibold" : ""}`}>Astro</span>
      </div>

      {/* Products Nav Item */}
      <div
        className="nav-item flex flex-col items-center cursor-pointer"
        onClick={() => navigate("/abhimantrit-products")}
      >
        <img
          src={activeItem === "products" ? yantra2 : yantra}
          className={`h-8 w-8 ${activeItem === "products" ? "opacity-90" : "products tag"}`}
          alt="Products tag"
        />
        <span className={`nav-label text-xs ${activeItem === "products" ? "font-semibold" : ""}`}>Products</span>
      </div>

      {/* Name Correction Nav Item */}
      <div
        className="nav-item flex flex-col items-center cursor-pointer"
        onClick={() => navigate("/name-correction/")}
      >
        <img
          src={activeItem === "name" ? nametag2 : nametag}
          className="h-8 w-8"
          alt={activeItem === "name" ? "Name tag clicked" : "Name tag"}
        />
        <span className={`nav-label text-xs ${activeItem === "name" ? "font-semibold" : ""}`}>Name</span>
      </div>

      {/* Orders Nav Item */}
      <div
        className="nav-item flex flex-col items-center cursor-pointer"
        onClick={() => navigate(`/orders/1/puja`)}
      >
        <img
         src={activeItem === "orders" ? My_Order2 : My_Order}
          className={`h-8 w-8 ${activeItem === "orders" ? "orders tag clicked" : "orders tag"}`}
          alt="Orders tag"
        />
        <span className={`nav-label text-xs ${activeItem === "orders" ? "font-semibold" : ""}`}>Orders</span>
      </div>
    </div>
  );
}

export default BottomNavBar;
