import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoName from "../assets/images/name_log.png";
import LogoIMAGE from "../assets/images/logo_image.png";


import facebook from "../assets/socialmedia/facebook.png";
import instagram from "../assets/socialmedia/instagram.png";
import whatsapp from "../assets/socialmedia/whatsapp.png";
import youtube from "../assets/socialmedia/youtube.png";
import securepay from "../assets/socialmedia/securepay.jpg";

import { siteConfig } from "../constants/Constants";

const nav = [
  {
    name: "Cancellation Policy",
    link: "/exchange-policy",
  },
  { name: "Privacy Policy", link: "/privacy-policy" },
  { name: "Shipping Policy", link: "/shipping-policy" },
  { name: "Terms & Conditions", link: "/term-and-condition" },
  { name: "Sitemap", href: "/sitemap.txt" },
];
export default function Footer() {
  const { homeData } = useSelector((state: any) => state?.homeReducer);
  const navigate = useNavigate();
  return (
    <div className=" mt-4 ">
      <Typography.Text className="text-center text-xl font-semibold w-full block mb-6 bg-gradient-to-r from-orange-500 to-yellow-500 bg-clip-text  text-transparent mt-8 px-3">
        Devpunya is the home to spiritual needs of Indians across the globe.
      </Typography.Text>

      <img src={securepay} />

      <div className="flex w-full px-4 py-4 bg-gradient-to-r from-orange-500 to-yellow-500  ">
        <div className="flex flex-col items-center justify-between w-[50%]">
          <Typography.Text className="text-lg mb-2 block text-medium text-white">
            Help
          </Typography.Text>
          {nav?.map((cat: any, index: any) => {
            return (
              <div
                onClick={() => {
                  cat?.href ? window.open(cat?.href) : navigate(cat?.link);
                }}
                key={index}
                className="w-full mb-2 text-center"
              >
                <Typography.Text className="text-xs text-white">
                  {cat?.name}
                </Typography.Text>
              </div>
            );
          })}
        </div>

        <div className=" w-[50%]">
          <Typography.Text className="text-lg mb-2 block text-medium text-white">
            Contact Us
          </Typography.Text>

          <Typography.Text className="text-xs text-white block">
            DEVPUNYA ENTERPRISE PRIVATE LIMITED D-624A, Malviya Nagar Jaipur-
            302017, Rajasthan
          </Typography.Text>

          <Typography.Text className="text-xs text-white block mt-2">
            devpunyahome@gmail.com
          </Typography.Text>

          <Typography.Text className="text-xs text-white block">
            +91 8829035685
          </Typography.Text>
          <div className="flex items-center mt-4">
             <a target="_blank" href="https://www.instagram.com/devpunyaofficial?igsh=YWU3bXZzY20zaHBl&utm_source=qr">
             <img className="w-6 h-6 ml-2" src={instagram} />
             </a>
             <a target="_blank" href="https://www.facebook.com/devpunyatemple/">
             <img className="w-6 h-6 ml-2" src={facebook} />
             </a>
             <a target="_blank" href="https://www.youtube.com/channel/UCEgPhfNmVnIrpATV9HYOEkg">
             <img className="w-6 h-6 ml-2" src={youtube} />
             </a>
             <a href="https://wa.me/917737998057" target="_blank">
             <img className="w-6 h-6 ml-2" src={whatsapp} /></a>


           
          </div>
        </div>

      </div>

    </div>
  );
}
