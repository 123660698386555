import React, { useState } from "react";
import { Input, Button, Divider, message } from "antd";
import { UserOutlined, SaveOutlined } from "@ant-design/icons";
import Page from "../../layouts/Page";
import { postRequest } from "../../utils/api.utils";
import { useNavigate, useParams } from "react-router-dom";

const AddNewAddress = () => {
  const userJSON = localStorage.getItem("user");
  const user = userJSON ? JSON.parse(userJSON) : null;

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState(
    user?.userDetails?.phone || ""
  );
  const [alternativeNumber, setAlternativeNumber] = useState("");
  const [email, setEmail] = useState("");
  const [flatNumber, setFlatNumber] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [loading, setLoading] = useState(false);

  const { id, type } = useParams();

  const navigate = useNavigate();

  const validateForm = () => {
    if (!name) {
      message.error("Name is required.");
      return false;
    }
    if (!mobileNumber) {
      message.error("Mobile Number is required.");
      return false;
    }

    if (!flatNumber) {
      message.error("Flat Number is required.");
      return false;
    }
    // if (!locality) {
    //   message.error("Locality is required.");
    //   return false;
    // }
    if (!city) {
      message.error("City is required.");
      return false;
    }
    if (!state) {
      message.error("State/Province is required.");
      return false;
    }
    if (!country) {
      message.error("Country is required.");
      return false;
    }
    if (!zipCode) {
      message.error("PinCode is required.");
      return false;
    }
    return true;
  };

  // Form submission handler
  const handleSubmit = async () => {
    // Handle form submission logic here
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    await postRequest(`/noauth-api/v1/login/user/getotp`, {
      phone: mobileNumber,
    })
      .then(async (data) => {
        await postRequest("/noauth-api/v1/login/user/verifyotp", {
          otp: "2262",
          phone: mobileNumber,
          session_id: data?.data?.results?.session_id,
        }).then(async (res) => {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res?.data?.results })
          );

          try {
            const reqBody = {
              name,
              phone: mobileNumber,
              alternativeNumber,
              email,
              flatNumber,
              locality,
              city,
              state,
              country,
              zipCode,
              landmark,
            };
            let res2 = null;

            if (type === "abhimantrit_product") {
              res2 = await postRequest(
                `/api/v1/abhimantrit_product/buyProduct`,
                {
                  product_id: id,
                  address: reqBody,
                },
                res?.data?.results.token
              );
            } else if (type === "gemstones") {
              res2 = await postRequest(
                `/api/v1/gemstone/buyProduct`,
                {
                  product_id: id,
                  address: reqBody,
                },
                res?.data?.results.token
              );
            }

            if (res2.data?.results?.razorpay_id) {
              if (!window.location.href.includes("localhost")) {
                pay(res2.data?.results?.razorpay_id);
              } else {
                message.warning(
                  "You are in your local. You do not have permission to call Gateway"
                );
                setLoading(false);
              }
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const pay = async (orderId) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      ...(orderId && { order_id: orderId }),
      handler: async function (response) {
        if (response?.razorpay_order_id) {
          try {
            await postRequest("/noauth-api/v1/payment/paymentOrderUpdate", {
              ...response,
              order_id: orderId,
            });
          } catch (error) {}

          message.success("Order placed successfully!");

          // abhimantrit_product
          navigate(`/success-page/${orderId}/${type}/gemstone`);
        }
        setLoading(false);
      },
      prefill: {
        contact: `${"91"}${mobileNumber}`,
        email: email || "devpunyahome@gmail.com",
      },
      notes: {
        address: "India",
      },
      theme: {
        color: "#FFA500",
      },
      modal: {
        ondismiss: async function () {
          setLoading(false);
          // orderId &&   await postRequest("/api/v1/payment/paymentOrderUpdate", {
          //   order_id: orderId,
          // });
        },
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", async function (response) {
      await postRequest("/noauth-api/v1/payment/paymentOrderUpdate", {
        ...response.error,
        ...response.error.metadata,
        order_id: orderId,
      });

      navigate(`/success-page/${orderId}/${type}/gemstone`);
      setLoading(false);
    });

    paymentObject.open();
  };

  return (
    <Page>
      <div className="max-w-3xl mx-auto p-3 bg-orange-100 min-h-screen">
        <h1 className="text-center font-semibold text-xl mb-4">
          Address for Home Delivery
        </h1>
        <div className="border-2 border-t-8 border-orange-500 bg-white p-4 rounded-lg shadow-md">
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Name: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Mobile Number: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Flat No / House No: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="Flat No / Building No / House No"
              value={flatNumber}
              onChange={(e) => setFlatNumber(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Locality:
            </label>
            <Input
              placeholder="Locality"
              value={locality}
              onChange={(e) => setLocality(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              City: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              State / Province: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Country: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              PinCode: <span className="text-red-500">*</span>
            </label>
            <Input
              placeholder="Postal Code / Pincode"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Landmark
            </label>
            <Input
              placeholder="Landmark (Optional)"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Alternative Number
            </label>
            <Input
              placeholder="Enter alternative mobile number"
              value={alternativeNumber}
              onChange={(e) => setAlternativeNumber(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Email ID:
            </label>
            <Input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <Divider />
        <div className="p-1 w-full bg-green-500 absolute bottom-0 left-0 right-0">
          <Button
            size="large"
            className="w-full border-none text-white  font-bold text-xl"
            // icon={<SaveOutlined />}
            onClick={handleSubmit}
            loading={loading}
          >
            Pay Now
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default AddNewAddress;
