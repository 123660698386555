import Route from "./routes";
import { useUser } from "./hooks/user.hook";
import { Provider } from "react-redux";
import store from "./redux/store";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import axios from "axios";
import vd from "./assets/open_loading.gif";
import { getRequest } from "./utils/api.utils";
import { viewContentPx } from "./events/facebookPexelsEvent";

const App = () => {
  const user = useUser();
  const location = useLocation();

  function getTokenFromUrl(url: string) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    return urlParams.get("token");
  }

  function getPlatformFromUrl(url: string) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    return urlParams.get("platform");
  }
  // const [country, setCountry] = useState("");

  useEffect(() => {
    let token: any = getTokenFromUrl(location?.search);
    let platform: any = getPlatformFromUrl(location?.search);
    const user: any = localStorage.getItem("user");

    if (!user && platform) {
      const fetchUserDetails = async () => {
        try {
          const res: any = await getRequest(`/api/v1/login/getUser`, token);
          localStorage.setItem("platform", platform);
          localStorage.setItem(
            "user",
            JSON.stringify({
              token,
              userDetails: res.results,
            })
          );
        } catch (error) {}
      };

      fetchUserDetails();
    }
  }, []);

  // useEffect(() => {
  //   const fetchCountry = async () => {
  //     try {
  //       const response1 = await axios.get("https://geolocation-db.com/json/");
  //       if (response1.data && response1.data.country_name) {
  //         setCountry(response1.data.country_name);
  //       } else {
  //         throw new Error("Invalid response from geolocation-db");
  //       }
  //     } catch (error1) {
  //       try {
  //         const response2 = await axios.get("https://ipapi.co/json");
  //         if (response2.data && response2.data.country_name) {
  //           setCountry(response2.data.country_name);
  //         } else {
  //           throw new Error("Invalid response from ipapi");
  //         }
  //       } catch (error2) {
  //         console.error("Both API calls failed:", error1, error2);
  //       }
  //     }
  //   };

  //   fetchCountry();
  // }, []);

  useEffect(() => {
    const setNewSession = (): void => {
      localStorage.clear();

      const newSessionId: string = uuidv4()?.replace("-", "");

      const newSessionStart: string = new Date().toISOString(); // Storing as ISO string

      localStorage.setItem("session_id", JSON.stringify(newSessionId));
      localStorage.setItem("session_start", newSessionStart);

      viewContentPx()
    };

    const sessionStartString: string | null = localStorage.getItem(
      "session_start"
    )
      ? localStorage.getItem("session_start")
      : "";
    let sessionStart: Date | null = null;

    if (sessionStartString) {
      try {
        sessionStart = new Date(sessionStartString); // Directly using the date string
      } catch (error) {
        console.error("Error parsing session start time:", error);
        setNewSession();
        return;
      }
    }

    if (sessionStart) {
      const currentTime: Date = new Date();
      const timeDiff: number = currentTime?.getTime() - sessionStart?.getTime();

      // clear session every 2 hour
      const onYearDaysInMillis = 360 * 24 * 60 * 60 * 1000
      if (timeDiff > onYearDaysInMillis) {
        setNewSession();
      }
    } else {
      setNewSession();
    }
  }, [location]);

  useEffect(() => {
    const sessionStartString: string | null = localStorage.getItem(
      "cart_disabled_session_start"
    )
      ? localStorage.getItem("cart_disabled_session_start")
      : "";
    let sessionStart: Date | null = null;

    if (sessionStartString) {
      try {
        sessionStart = new Date(sessionStartString); // Directly using the date string
      } catch (error) {
        console.error("Error parsing session start time:", error);

        return;
      }
    }

    if (sessionStart) {
      const currentTime: Date = new Date();
      const timeDiff: number = currentTime?.getTime() - sessionStart?.getTime();

      const ninetyDaysInMillis = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
      if (timeDiff > ninetyDaysInMillis) {
        localStorage.removeItem("cart_disabled");
        localStorage.removeItem("cart_disabled_session_start");
      }
    }
  }, [location]);

  return user ? (
    // <UserContext.Provider value={user}>
    <div className="flex justify-center w-full relative">
      <div
        className={`${
          location.pathname?.includes("kundali-report") ? "" : "w-[400px]"
        }`}
      >
        <Provider store={store}>
          <Route />
        </Provider>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      {/* <Spin  size="large" /> */}
      <img src={vd} alt="loading..." />
      
    </div>
  );
};

export default App;
