// src/components/CustomButton.js
import React from 'react';
import PropTypes from 'prop-types';
import './CustomButton.css'; // Import CSS for the animation only

const CustomButton = ({ 
  text, 
  size = 'md', 
  onClick, 
  disabled = false, 
  fixed = false,
  style
}) => {
  const sizeClasses = {
    xs: 'py-1 px-2 text-xs',
    sm: 'py-2 px-3 text-sm',
    md: 'py-3 px-4 text-base',
    lg: 'py-4 px-6 text-lg',
    xl: 'py-5 px-8 text-xl',
    '2xl': 'py-6 px-10 text-2xl',
  };

  const buttonClass = `
    button 
    ${sizeClasses[size]} 
    ${disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-opacity-90'}
    ${fixed ? 'fixed bottom-4 w-11/12' : ''}
    w-full
    text-black 
    rounded-lg 
    font-semibold
    relative
    overflow-hidden
    transition-all
  `;

  return (
    <div className={`container-flash-btn ${fixed ? 'fixed bottom-4' : ''}`}>
      <button 
        className={buttonClass} 
        onClick={onClick} 
        disabled={disabled}
        style={{
          background: 'linear-gradient(91deg, #CFA346 0.84%, #E0BA59 27.15%, #F7EBAE 43.84%, #F3DF89 53.45%, #E0BA59 76.22%, #A8701C 97.97%)',
          ...style
        }}
      >
        {text}
      </button>
    </div>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fixed: PropTypes.bool,
};

export default CustomButton;
