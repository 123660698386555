import React from "react";
import { Row, Col } from "antd";
import {
  BookOutlined,
  HeartOutlined,
  BankOutlined,
  UserOutlined,
  CarOutlined,
  MedicineBoxOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import career from "../../assets/categoires_astro/career.png";
import marriage from "../../assets/categoires_astro/marriage.png";
import finance from "../../assets/categoires_astro/finance.png";
import love from "../../assets/categoires_astro/love.png";
import childbirth from "../../assets/categoires_astro/childbirth.png";
import business from "../../assets/categoires_astro/business.png";
import education from "../../assets/categoires_astro/education.png";
import travel from "../../assets/categoires_astro/travel.png";
import health from "../../assets/categoires_astro/health.png";
import legal from "../../assets/categoires_astro/legal.png";
import { useNavigate } from "react-router-dom";

const categories = [
  { key: "career", label: "Career", icon: career },
  { key: "marriage", label: "Marriage", icon: marriage },
  { key: "finance", label: "Finance", icon: finance },
  { key: "love", label: "Love", icon: love },
  { key: "education", label: "Education", icon: education },
  { key: "childbirth", label: "Child Birth", icon: childbirth },
  { key: "business", label: "Business", icon: business },
  { key: "legal", label: "Legal", icon: legal },
  { key: "travel", label: "Travel", icon: travel },
  { key: "health", label: "Health", icon: health },
];

function CategoryGrid({ setActiveCategory, activeCategory,style }) {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <Row gutter={[8, 8]}>
          {categories.map((category) => (
            <Col key={category.key} style={style}>
              <div
                className={`flex flex-col items-center justify-center p-2 rounded-lg cursor-pointer transition-all duration-200 
                  ${
                    activeCategory === category.key
                      ? "bg-orange-500 text-white"
                      : "bg-gray-200 hover:bg-gray-300"
                  }`}
                onClick={() => {
                  if (window.location.pathname === "/" || window.location.pathname?.includes("sankalp-form-submitted") || window.location.pathname.includes("success-page") || window.location.pathname.includes("after-puja-add-on")) {
                    navigate(`/astro-guru/find`, { state: category });
                  } else {
                    setActiveCategory(category.key);
                  }
                }}
              >
                <div
                  className={`text-sm ${
                    activeCategory === category.key
                      ? "text-white"
                      : "text-gray-600"
                  }`}
                >
                  <img src={category.icon} className="w-[30px] h-[30px]" />
                  {/* {category.icon} */}
                </div>
                <div
                  className={`mt-2 text-xs font-semibold text-center ${
                    activeCategory === category.key
                      ? "text-white"
                      : "text-gray-600"
                  }`}
                >
                  {category.label}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default CategoryGrid;
