import React, { useEffect, useState } from "react";
import Page from "../../layouts/Page";
import TrendingPujaCard from "./components/TrendingPujaCard";
import { getRequest } from "../../utils/api.utils";
import Loader from "../../components/Loader";
import { Typography } from "antd";
import ChatWithUs from "../../components/generic/ChatWithUs";
import SeoHelmet from "../../components/SeoHelmet";
import PujaData from "../../data/PujaData";
import axios from "axios";
import { countryCodes } from "../../utils/helper.utils";

export default function PoojaApp() {
  const [poojaData, setPoojaData] = useState<any>(PujaData);
  const [countryCode, setCountryCode] = useState("");
  const [filteredPuja, setFilteredPuja] = useState<any>(PujaData);


  // const fetchCountry = async () => {
  //   try {
  //     const response1 = await axios.get("https://geolocation-db.com/json/");
  //     if (response1.data && response1.data.country_name) {
  //       setCountryCode(response1.data.country_code);
  //     } else {
  //       throw new Error("Invalid response from geolocation-db");
  //     }
  //   } catch (error1) {
  //     try {
  //       const response2 = await axios.get("https://ipapi.co/json");
  //       if (response2.data && response2.data.country_name) {
  //         setCountryCode(response2.data.country_code);
  //       } else {
  //         throw new Error("Invalid response from ipapi");
  //       }
  //     } catch (error2) {
  //       console.error("Both API calls failed:", error1, error2);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchCountry();
  // }, []);


 // Fetching the pooja data
 useEffect(() => {
  getRequest("/noauth-api/v1/product/pooja").then((res:any) => {
    const sortedData = sortResultsByCurrency(res.results);
    setPoojaData(sortedData);
  });
}, []);

  // Sorting function
  const sortResultsByCurrency = (data:any) => {
    return data.sort((a:any, b:any) => {
      const aHasINR = a.packages && a.packages.some((pkg:any) => pkg.currency === 'INR');
      const bHasINR = b.packages && b.packages.some((pkg:any) => pkg.currency === 'INR');
      return bHasINR - aHasINR;
    });
  };
  useEffect(() => {
    if (countryCode && poojaData) {
      let currencyData = countryCodes.find(
        (ct: any) => ct.country?.toLowerCase() === countryCode?.toLowerCase()
      );
      let currency = currencyData?.currency;

      let data = poojaData.filter((puj:any) => puj?.packages[0].currency === currency)

      setFilteredPuja(data);
      
    } else {
      setFilteredPuja(poojaData);
    }
  }, [countryCode, poojaData]);


  if (!filteredPuja) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }
  return (
    <div>

<SeoHelmet title="Sacred Vedic Puja Services: Browse Listings for Your Need" description="Browse through our collection of sacred puja services, designed to cater to your spiritual requirements. Explore various listings, ranging from traditional rituals to contemporary practices. Connect with vedic priest and discover authentic puja experiences tailored to meet your needs. Start your spiritual journey today."/>

<ChatWithUs />

<div>
<Typography.Text className="text-center block mb-2 mt-8 text-lg font-semibold">
    Explore Upcoming Pujas{" "}
  </Typography.Text>
  <Typography.Text type="secondary" className="block  text-center">
  Discover and participate in our upcoming spiritual ceremonies for enhanced blessings and divine connection.
  </Typography.Text>
  {filteredPuja?.map((item: any) => {
    return (
      <div className="mt-4" key={item.id}>
        <TrendingPujaCard item={item} />
      </div>
    );
  })}
</div>

    </div>
  );
}
